exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-services-js": () => import("./../../../src/pages/all-services.js" /* webpackChunkName: "component---src-pages-all-services-js" */),
  "component---src-pages-build-your-own-online-store-js": () => import("./../../../src/pages/build-your-own-online-store.js" /* webpackChunkName: "component---src-pages-build-your-own-online-store-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-courses-build-your-own-online-store-checkout-get-instant-access-js": () => import("./../../../src/pages/courses/build-your-own-online-store/checkout/get-instant-access.js" /* webpackChunkName: "component---src-pages-courses-build-your-own-online-store-checkout-get-instant-access-js" */),
  "component---src-pages-courses-build-your-own-online-store-checkout-order-complete-js": () => import("./../../../src/pages/courses/build-your-own-online-store/checkout/order-complete.js" /* webpackChunkName: "component---src-pages-courses-build-your-own-online-store-checkout-order-complete-js" */),
  "component---src-pages-courses-build-your-own-online-store-checkout-oto-js": () => import("./../../../src/pages/courses/build-your-own-online-store/checkout/oto.js" /* webpackChunkName: "component---src-pages-courses-build-your-own-online-store-checkout-oto-js" */),
  "component---src-pages-courses-build-your-own-online-store-continue-course-js": () => import("./../../../src/pages/courses/build-your-own-online-store/continue-course.js" /* webpackChunkName: "component---src-pages-courses-build-your-own-online-store-continue-course-js" */),
  "component---src-pages-courses-build-your-own-online-store-day-1-course-intro-js": () => import("./../../../src/pages/courses/build-your-own-online-store/day-1-course-intro.js" /* webpackChunkName: "component---src-pages-courses-build-your-own-online-store-day-1-course-intro-js" */),
  "component---src-pages-courses-build-your-own-online-store-day-10-navigation-js": () => import("./../../../src/pages/courses/build-your-own-online-store/day-10-navigation.js" /* webpackChunkName: "component---src-pages-courses-build-your-own-online-store-day-10-navigation-js" */),
  "component---src-pages-courses-build-your-own-online-store-day-11-choose-how-to-get-paid-js": () => import("./../../../src/pages/courses/build-your-own-online-store/day-11-choose-how-to-get-paid.js" /* webpackChunkName: "component---src-pages-courses-build-your-own-online-store-day-11-choose-how-to-get-paid-js" */),
  "component---src-pages-courses-build-your-own-online-store-day-12-connect-custom-domain-js": () => import("./../../../src/pages/courses/build-your-own-online-store/day-12-connect-custom-domain.js" /* webpackChunkName: "component---src-pages-courses-build-your-own-online-store-day-12-connect-custom-domain-js" */),
  "component---src-pages-courses-build-your-own-online-store-day-13-create-a-custom-email-address-js": () => import("./../../../src/pages/courses/build-your-own-online-store/day-13-create-a-custom-email-address.js" /* webpackChunkName: "component---src-pages-courses-build-your-own-online-store-day-13-create-a-custom-email-address-js" */),
  "component---src-pages-courses-build-your-own-online-store-day-14-launch-your-store-js": () => import("./../../../src/pages/courses/build-your-own-online-store/day-14-launch-your-store.js" /* webpackChunkName: "component---src-pages-courses-build-your-own-online-store-day-14-launch-your-store-js" */),
  "component---src-pages-courses-build-your-own-online-store-day-2-kickstart-your-store-js": () => import("./../../../src/pages/courses/build-your-own-online-store/day-2-kickstart-your-store.js" /* webpackChunkName: "component---src-pages-courses-build-your-own-online-store-day-2-kickstart-your-store-js" */),
  "component---src-pages-courses-build-your-own-online-store-day-3-make-it-your-own-js": () => import("./../../../src/pages/courses/build-your-own-online-store/day-3-make-it-your-own.js" /* webpackChunkName: "component---src-pages-courses-build-your-own-online-store-day-3-make-it-your-own-js" */),
  "component---src-pages-courses-build-your-own-online-store-day-4-add-your-first-product-js": () => import("./../../../src/pages/courses/build-your-own-online-store/day-4-add-your-first-product.js" /* webpackChunkName: "component---src-pages-courses-build-your-own-online-store-day-4-add-your-first-product-js" */),
  "component---src-pages-courses-build-your-own-online-store-day-5-shipping-settings-js": () => import("./../../../src/pages/courses/build-your-own-online-store/day-5-shipping-settings.js" /* webpackChunkName: "component---src-pages-courses-build-your-own-online-store-day-5-shipping-settings-js" */),
  "component---src-pages-courses-build-your-own-online-store-day-6-contact-page-js": () => import("./../../../src/pages/courses/build-your-own-online-store/day-6-contact-page.js" /* webpackChunkName: "component---src-pages-courses-build-your-own-online-store-day-6-contact-page-js" */),
  "component---src-pages-courses-build-your-own-online-store-day-7-privacy-policy-js": () => import("./../../../src/pages/courses/build-your-own-online-store/day-7-privacy-policy.js" /* webpackChunkName: "component---src-pages-courses-build-your-own-online-store-day-7-privacy-policy-js" */),
  "component---src-pages-courses-build-your-own-online-store-day-8-cookie-policy-js": () => import("./../../../src/pages/courses/build-your-own-online-store/day-8-cookie-policy.js" /* webpackChunkName: "component---src-pages-courses-build-your-own-online-store-day-8-cookie-policy-js" */),
  "component---src-pages-courses-build-your-own-online-store-day-9-returns-policy-js": () => import("./../../../src/pages/courses/build-your-own-online-store/day-9-returns-policy.js" /* webpackChunkName: "component---src-pages-courses-build-your-own-online-store-day-9-returns-policy-js" */),
  "component---src-pages-courses-build-your-own-online-store-instant-access-js": () => import("./../../../src/pages/courses/build-your-own-online-store/instant-access.js" /* webpackChunkName: "component---src-pages-courses-build-your-own-online-store-instant-access-js" */),
  "component---src-pages-forms-build-your-own-online-store-js": () => import("./../../../src/pages/forms/build-your-own-online-store.js" /* webpackChunkName: "component---src-pages-forms-build-your-own-online-store-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-reads-dropshipping-misconceptions-js": () => import("./../../../src/pages/reads/dropshipping-misconceptions.js" /* webpackChunkName: "component---src-pages-reads-dropshipping-misconceptions-js" */),
  "component---src-pages-reads-why-quit-amazon-js": () => import("./../../../src/pages/reads/why-quit-amazon.js" /* webpackChunkName: "component---src-pages-reads-why-quit-amazon-js" */),
  "component---src-pages-services-branding-applied-js": () => import("./../../../src/pages/services/branding-applied.js" /* webpackChunkName: "component---src-pages-services-branding-applied-js" */),
  "component---src-pages-services-branding-js": () => import("./../../../src/pages/services/branding.js" /* webpackChunkName: "component---src-pages-services-branding-js" */),
  "component---src-pages-services-business-card-js": () => import("./../../../src/pages/services/business-card.js" /* webpackChunkName: "component---src-pages-services-business-card-js" */),
  "component---src-pages-services-connecting-a-new-domain-js": () => import("./../../../src/pages/services/connecting-a-new-domain.js" /* webpackChunkName: "component---src-pages-services-connecting-a-new-domain-js" */),
  "component---src-pages-services-cookie-policy-js": () => import("./../../../src/pages/services/cookie-policy.js" /* webpackChunkName: "component---src-pages-services-cookie-policy-js" */),
  "component---src-pages-services-cookie-popup-js": () => import("./../../../src/pages/services/cookie-popup.js" /* webpackChunkName: "component---src-pages-services-cookie-popup-js" */),
  "component---src-pages-services-custom-email-address-js": () => import("./../../../src/pages/services/custom-email-address.js" /* webpackChunkName: "component---src-pages-services-custom-email-address-js" */),
  "component---src-pages-services-flyer-design-js": () => import("./../../../src/pages/services/flyer-design.js" /* webpackChunkName: "component---src-pages-services-flyer-design-js" */),
  "component---src-pages-services-photo-background-removal-js": () => import("./../../../src/pages/services/photo-background-removal.js" /* webpackChunkName: "component---src-pages-services-photo-background-removal-js" */),
  "component---src-pages-services-privacy-policy-js": () => import("./../../../src/pages/services/privacy-policy.js" /* webpackChunkName: "component---src-pages-services-privacy-policy-js" */),
  "component---src-pages-services-product-descriptions-js": () => import("./../../../src/pages/services/product-descriptions.js" /* webpackChunkName: "component---src-pages-services-product-descriptions-js" */),
  "component---src-pages-services-product-labels-design-js": () => import("./../../../src/pages/services/product-labels-design.js" /* webpackChunkName: "component---src-pages-services-product-labels-design-js" */),
  "component---src-pages-services-product-photo-editing-js": () => import("./../../../src/pages/services/product-photo-editing.js" /* webpackChunkName: "component---src-pages-services-product-photo-editing-js" */),
  "component---src-pages-services-returns-policy-js": () => import("./../../../src/pages/services/returns-policy.js" /* webpackChunkName: "component---src-pages-services-returns-policy-js" */),
  "component---src-pages-services-shopify-store-setup-js": () => import("./../../../src/pages/services/shopify-store-setup.js" /* webpackChunkName: "component---src-pages-services-shopify-store-setup-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-themes-takeout-features-image-filters-js": () => import("./../../../src/pages/themes/takeout/features/image-filters.js" /* webpackChunkName: "component---src-pages-themes-takeout-features-image-filters-js" */),
  "component---src-pages-themes-takeout-features-layout-control-js": () => import("./../../../src/pages/themes/takeout/features/layout-control.js" /* webpackChunkName: "component---src-pages-themes-takeout-features-layout-control-js" */),
  "component---src-pages-themes-takeout-features-menu-grid-view-js": () => import("./../../../src/pages/themes/takeout/features/menu-grid-view.js" /* webpackChunkName: "component---src-pages-themes-takeout-features-menu-grid-view-js" */),
  "component---src-pages-themes-takeout-features-mobile-experience-js": () => import("./../../../src/pages/themes/takeout/features/mobile-experience.js" /* webpackChunkName: "component---src-pages-themes-takeout-features-mobile-experience-js" */),
  "component---src-pages-themes-takeout-features-opening-hours-js": () => import("./../../../src/pages/themes/takeout/features/opening-hours.js" /* webpackChunkName: "component---src-pages-themes-takeout-features-opening-hours-js" */),
  "component---src-pages-themes-takeout-features-shopping-experience-js": () => import("./../../../src/pages/themes/takeout/features/shopping-experience.js" /* webpackChunkName: "component---src-pages-themes-takeout-features-shopping-experience-js" */),
  "component---src-pages-themes-takeout-features-storytelling-js": () => import("./../../../src/pages/themes/takeout/features/storytelling.js" /* webpackChunkName: "component---src-pages-themes-takeout-features-storytelling-js" */),
  "component---src-pages-themes-takeout-features-tags-js": () => import("./../../../src/pages/themes/takeout/features/tags.js" /* webpackChunkName: "component---src-pages-themes-takeout-features-tags-js" */),
  "component---src-pages-themes-takeout-help-request-a-feature-js": () => import("./../../../src/pages/themes/takeout/help/request-a-feature.js" /* webpackChunkName: "component---src-pages-themes-takeout-help-request-a-feature-js" */),
  "component---src-pages-themes-takeout-help-theme-support-js": () => import("./../../../src/pages/themes/takeout/help/theme-support.js" /* webpackChunkName: "component---src-pages-themes-takeout-help-theme-support-js" */),
  "component---src-pages-themes-takeout-index-js": () => import("./../../../src/pages/themes/takeout/index.js" /* webpackChunkName: "component---src-pages-themes-takeout-index-js" */),
  "component---src-pages-themes-takeout-pages-404-js": () => import("./../../../src/pages/themes/takeout/pages/404.js" /* webpackChunkName: "component---src-pages-themes-takeout-pages-404-js" */),
  "component---src-pages-themes-takeout-pages-blog-js": () => import("./../../../src/pages/themes/takeout/pages/blog.js" /* webpackChunkName: "component---src-pages-themes-takeout-pages-blog-js" */),
  "component---src-pages-themes-takeout-pages-blog-post-js": () => import("./../../../src/pages/themes/takeout/pages/blog-post.js" /* webpackChunkName: "component---src-pages-themes-takeout-pages-blog-post-js" */),
  "component---src-pages-themes-takeout-pages-cart-js": () => import("./../../../src/pages/themes/takeout/pages/cart.js" /* webpackChunkName: "component---src-pages-themes-takeout-pages-cart-js" */),
  "component---src-pages-themes-takeout-pages-collection-js": () => import("./../../../src/pages/themes/takeout/pages/collection.js" /* webpackChunkName: "component---src-pages-themes-takeout-pages-collection-js" */),
  "component---src-pages-themes-takeout-pages-collection-list-js": () => import("./../../../src/pages/themes/takeout/pages/collection-list.js" /* webpackChunkName: "component---src-pages-themes-takeout-pages-collection-list-js" */),
  "component---src-pages-themes-takeout-pages-contact-js": () => import("./../../../src/pages/themes/takeout/pages/contact.js" /* webpackChunkName: "component---src-pages-themes-takeout-pages-contact-js" */),
  "component---src-pages-themes-takeout-pages-gift-card-js": () => import("./../../../src/pages/themes/takeout/pages/gift-card.js" /* webpackChunkName: "component---src-pages-themes-takeout-pages-gift-card-js" */),
  "component---src-pages-themes-takeout-pages-page-js": () => import("./../../../src/pages/themes/takeout/pages/page.js" /* webpackChunkName: "component---src-pages-themes-takeout-pages-page-js" */),
  "component---src-pages-themes-takeout-pages-password-js": () => import("./../../../src/pages/themes/takeout/pages/password.js" /* webpackChunkName: "component---src-pages-themes-takeout-pages-password-js" */),
  "component---src-pages-themes-takeout-pages-product-js": () => import("./../../../src/pages/themes/takeout/pages/product.js" /* webpackChunkName: "component---src-pages-themes-takeout-pages-product-js" */),
  "component---src-pages-themes-takeout-pages-search-js": () => import("./../../../src/pages/themes/takeout/pages/search.js" /* webpackChunkName: "component---src-pages-themes-takeout-pages-search-js" */),
  "component---src-pages-themes-takeout-sections-announcement-js": () => import("./../../../src/pages/themes/takeout/sections/announcement.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-announcement-js" */),
  "component---src-pages-themes-takeout-sections-blog-posts-js": () => import("./../../../src/pages/themes/takeout/sections/blog-posts.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-blog-posts-js" */),
  "component---src-pages-themes-takeout-sections-collage-js": () => import("./../../../src/pages/themes/takeout/sections/collage.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-collage-js" */),
  "component---src-pages-themes-takeout-sections-collapsible-content-js": () => import("./../../../src/pages/themes/takeout/sections/collapsible-content.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-collapsible-content-js" */),
  "component---src-pages-themes-takeout-sections-collection-list-js": () => import("./../../../src/pages/themes/takeout/sections/collection-list.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-collection-list-js" */),
  "component---src-pages-themes-takeout-sections-contact-form-js": () => import("./../../../src/pages/themes/takeout/sections/contact-form.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-contact-form-js" */),
  "component---src-pages-themes-takeout-sections-custom-liquid-js": () => import("./../../../src/pages/themes/takeout/sections/custom-liquid.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-custom-liquid-js" */),
  "component---src-pages-themes-takeout-sections-divider-js": () => import("./../../../src/pages/themes/takeout/sections/divider.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-divider-js" */),
  "component---src-pages-themes-takeout-sections-email-signup-js": () => import("./../../../src/pages/themes/takeout/sections/email-signup.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-email-signup-js" */),
  "component---src-pages-themes-takeout-sections-featured-collection-js": () => import("./../../../src/pages/themes/takeout/sections/featured-collection.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-featured-collection-js" */),
  "component---src-pages-themes-takeout-sections-featured-product-js": () => import("./../../../src/pages/themes/takeout/sections/featured-product.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-featured-product-js" */),
  "component---src-pages-themes-takeout-sections-footer-js": () => import("./../../../src/pages/themes/takeout/sections/footer.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-footer-js" */),
  "component---src-pages-themes-takeout-sections-header-js": () => import("./../../../src/pages/themes/takeout/sections/header.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-header-js" */),
  "component---src-pages-themes-takeout-sections-image-banner-js": () => import("./../../../src/pages/themes/takeout/sections/image-banner.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-image-banner-js" */),
  "component---src-pages-themes-takeout-sections-image-gallery-js": () => import("./../../../src/pages/themes/takeout/sections/image-gallery.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-image-gallery-js" */),
  "component---src-pages-themes-takeout-sections-images-with-text-js": () => import("./../../../src/pages/themes/takeout/sections/images-with-text.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-images-with-text-js" */),
  "component---src-pages-themes-takeout-sections-multicolumn-image-buttons-js": () => import("./../../../src/pages/themes/takeout/sections/multicolumn-image-buttons.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-multicolumn-image-buttons-js" */),
  "component---src-pages-themes-takeout-sections-multicolumn-text-js": () => import("./../../../src/pages/themes/takeout/sections/multicolumn-text.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-multicolumn-text-js" */),
  "component---src-pages-themes-takeout-sections-narrow-banner-js": () => import("./../../../src/pages/themes/takeout/sections/narrow-banner.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-narrow-banner-js" */),
  "component---src-pages-themes-takeout-sections-page-js": () => import("./../../../src/pages/themes/takeout/sections/page.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-page-js" */),
  "component---src-pages-themes-takeout-sections-rich-text-js": () => import("./../../../src/pages/themes/takeout/sections/rich-text.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-rich-text-js" */),
  "component---src-pages-themes-takeout-sections-slideshow-js": () => import("./../../../src/pages/themes/takeout/sections/slideshow.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-slideshow-js" */),
  "component---src-pages-themes-takeout-sections-social-links-js": () => import("./../../../src/pages/themes/takeout/sections/social-links.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-social-links-js" */),
  "component---src-pages-themes-takeout-sections-table-js": () => import("./../../../src/pages/themes/takeout/sections/table.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-table-js" */),
  "component---src-pages-themes-takeout-sections-video-js": () => import("./../../../src/pages/themes/takeout/sections/video.js" /* webpackChunkName: "component---src-pages-themes-takeout-sections-video-js" */),
  "component---src-pages-themes-takeout-theme-settings-js": () => import("./../../../src/pages/themes/takeout/theme-settings.js" /* webpackChunkName: "component---src-pages-themes-takeout-theme-settings-js" */),
  "component---src-pages-tools-cookie-policy-generator-js": () => import("./../../../src/pages/tools/cookie-policy-generator.js" /* webpackChunkName: "component---src-pages-tools-cookie-policy-generator-js" */),
  "component---src-pages-tools-privacy-policy-generator-js": () => import("./../../../src/pages/tools/privacy-policy-generator.js" /* webpackChunkName: "component---src-pages-tools-privacy-policy-generator-js" */),
  "component---src-pages-tools-product-description-generator-js": () => import("./../../../src/pages/tools/product-description-generator.js" /* webpackChunkName: "component---src-pages-tools-product-description-generator-js" */),
  "component---src-pages-tools-returns-policy-generator-js": () => import("./../../../src/pages/tools/returns-policy-generator.js" /* webpackChunkName: "component---src-pages-tools-returns-policy-generator-js" */)
}

